/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from "react";
import "./home.css";
import UserContext from "../../../Store/user-context";
import Button from "../../ds-gov/Button";
import { useHistory } from "react-router-dom";


function Home() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  localStorage.setItem('countFont', 0);

  return (
    <div className={`${userContext.contrast}MainPage`}>
      <div className={`${userContext.contrast}Container`}>
        <div>
          <div className="section">
            <div>
              <p className={`${userContext.contrast}titleHome`}>
                Bem vindo ao Painel PNLD!
              </p>
              <div className="sized_box" />
              <p className={`${userContext.contrast}Subtitle`}>
                O painel do Programa Nacional do Livro e do Material Didático.
              </p>    
                        
              <div className="sized_box" />
              <div className="sized_box" />
              <div className="sized_box" />

              <p className={`${userContext.contrast}Question`}>O que é?</p>
              <div className="sized_box" />
              <p className={`${userContext.contrast}Content`}>
                O PNLD é voltado à distribuição de obras didáticas, pedagógicas
                e literárias, e outros materiais de apoio à prática educativa,
                destinados aos alunos e professores das escolas públicas de
                educação básica do País. O PNLD também contempla as instituições
                comunitárias, confessionais ou filantrópicas sem fins lucrativos
                e conveniadas com o Poder Público. O Painel PNLD é o espaço
                dedicado a profissionais e instituições que irão fazer o
                monitoramento e acompanhamento de informações e dados
                relacionados ao progama.
              </p>
            </div>
            <div className="section_button" >
              <Button
                bground="primary"
                psize="medium"
                label="Dashboard"
                onclick={() => history.push("/dashboard_selection")}
                inverted={userContext.contrast === "contrast" && "inverted"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
