/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { getHexById } from "./colors";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./tooltip/custom-tooltip";

const BarChartComponent = ({
  data,
  contrast,
  keys,
  fullPage,
  isStackedBar,
  label,
}) => {
  const [base, setBase] = useState(0);
  const [min, setMin] = useState(1);

  function handleChangeBase(event) {
    setBase(event.target.value);
  }
  function graphSlider() {
    return (
      <div style={fullPage ? { height: "130%" } : { height: "80%" }}>
        <Slider
          sx={contrast === "contrast" ? {
            color: "#77a8f7",
            '& input[type="range"]': {
              WebkitAppearance: "slider-vertical",
            },
          } : {
            color: "var(--color-primary-default)",
            '& input[type="range"]': {
              WebkitAppearance: "slider-vertical",
            },
          }}
          value={base}
          onChange={(e) => handleChangeBase(e)}
          min={0}
          max={min}
          orientation="vertical"
          aria-label="Base"
          valueLabelDisplay="auto"
          valueLabelFormat={(min) => <div>{DataFormater(Number(min))}</div>}
        />
      </div>
    );
  }

  const DataFormater = (number) => {
    if (number >= 1000000000)
      return (number / 1000000000).toFixed(2).toString() + "B";
    else if (number >= 1000000)
      return (number / 1000000).toFixed(2).toString() + "M";
    else if (number >= 1000) return (number / 1000).toFixed(2).toString() + "K";
    else return Math.trunc(number).toString();
  };

  let objectKeys;

  if (data.length > 0) {
    objectKeys = Object.keys(data[0]);
  }

  function renderText(value) {
    return value;
  }

  if (data.length > 0) {

    return (
      <div className="chart" style={{ display: "flex", flexDirection: "row", position: "absolute" }}>
        {!isStackedBar && graphSlider()}
        <ResponsiveContainer
          debounce={1}
          minWidth={"0%"}
          width={"90%"}
          height={fullPage ? "150%" : "100%"}
        >
          <BarChart data={data} barGap={2} barCategoryGap={"5%"} barSize={80}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              style={{
                fontSize: "14px",
                fontFamily: "Arial",
              }}
              dataKey="period"
              stroke={contrast === "contrast" ? "white" : "black"}
            />
            <YAxis
              type="number"
              style={{
                fontSize: "14px",
                fontFamily: "Arial",
              }}
              allowDataOverflow={false}
              tickFormatter={(number) => DataFormater(Number(number))}
              domain={[
                (dataMin) => {
                  setMin(Number(dataMin) > 0 ? Number(dataMin) : 1);
                  return Number(base) > dataMin ? dataMin : Number(base) || 0;
                },
                (dataMax) => {
                  return dataMax * 1.1 - (base * ((dataMax * 0.1) / min)).toFixed(0)
                }
              ]}
              stroke={contrast === "contrast" ? "white" : "black"}
            />
            <Tooltip
              content={
                <CustomTooltip contrast={contrast} formatter={DataFormater} />
              }
              />
            <Legend />
            {keys && Math.ceil(keys.length / data.length) < 40 && (
              <Legend formatter={renderText} />
            )}
            {objectKeys?.map((key, index) => {
              if (key !== "period") {
                return isStackedBar ? (
                  <Bar
                    key={index}
                    dataKey={key}
                    stackId={"a"}
                    fill={getHexById(index, contrast)}
                  />
                ) : (
                  <Bar key={index} dataKey={key} fill={getHexById(index, contrast)} />
                );
              }
              return null;
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  } else return "Nenhum dado foi encontrado.";
};

export default BarChartComponent;