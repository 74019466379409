export function renderSelect(selected, placeholder) {
  let str = placeholder;
  if (selected.length === 1) {
    str = `${selected[0].label}`;
  } else if (selected.length > 1 ) {
    str = `${selected[0].label} + (${selected.length - 1})`;
  }
  return str;
}

export function onChangeOption(index, options, onChange, setSelected) {
  let newOptions = [...options];
  let lastSelected = index[index.length - 1];
  let checkedarray = [];
  let selected = [];
  /* if deselect option is selected, then loop through the array and uncheck all options  */
  if (index.length === 0 || lastSelected === -2) {
    onChange([]);
    for (let i = 0; i < newOptions.length; i++) {
      const option = newOptions[i];
      option.checked = false;
    }
    setSelected([]);
  } else {
    /* if selectAll option is selected, then loop through the array and check all options  */
    if (lastSelected === -1) {
      for (let i = 0; i < newOptions.length; i++) {
        const option = newOptions[i];
        option.checked = true;
        checkedarray[i] = option.id;
        selected[i] = i;
      }
      onChange(checkedarray);
      setSelected(selected);
    } else {
        for (let i = 0; i < index.length; i++) {
          // if(checkedarray.length < 3){
            const option = newOptions[index[i]];
            checkedarray[i] = option.id;
          // }
        }
        onChange(checkedarray);
        setSelected(index);

    }
  }
}
