/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useEffect, useState } from "react";
import "./Styles/input.css";
import "./Styles/Select.css";
import api_obj from "../../api_configuration";
function Input(props) {
  /*
    **Parameters**
  - brInputClassName = string;
  - labelFor = string;
  - inputId = string;
  - labelText = string;
  - inputType = string;
  - inputPlaceholder = string;
  - inputValue = string;
  - handleChange = function;
  - onBlur = function;
  - handleClick = function;
  - hasButton = boolean;
  - buttonClassName = string;
  - buttonType = string;
  - buttonIcon = string;
  - buttonArialLabel = string;
  - hasToolTip = boolean;
  - toolTipText = string;
  - showMessage = string;
  - messageText = string;
  - feedBackType = string;
  - messageIcon = string;
  - autocompleteData = array;
  */

  let brInputClassName = props.brInputClassName || "br-input";
  let labelFor = props.labelFor || "default";
  let inputId = props.inputId || "default";
  let labelText = props.labelText || "O que você procura?";
  let inputType = props.inputType || "text";
  let inputPlaceholder = props.inputPlaceholder || "Digite o que você procura?";
  let inputValue = props.inputValue;
  let handleChange = props.handleChange || function () {};
  let handleClick = props.handleClick || function () {};
  let hasButton = props.hasButton || false;
  let buttonClassName = props.buttonClassName || "br-button circle small";
  let buttonType = props.buttonType || "button";
  let buttonIcon = props.buttonIcon || "fas fa-search";
  let buttonArialLabel = props.buttonArialLabel || "Pesquisar";
  let hasToolTip = props.hasToolTip || false;
  let toolTipText = props.toolTipText || "Pesquisar";
  let showMessage = props.showMessage || false;
  let messageText = props.messageText || "";
  let feedBackType = props.feedBackType || "success";
  let messageIcon = props.messageIcon || "fas fa-check";
  let acRoute = props.autocompleteRoute || "";
  let acKey = props.autocompleteKey || "";
  let setAcLoading = props.setAcLoading || function () {};
  let index = props.index || 0;
  let isAutocomplete = props.isAutocomplete || false;
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(inputValue);
  const [suggestion, setSuggestions] = useState();
  useEffect(() => {
    if (isAutocomplete && acKey !== "") {
      setAcLoading(true);
      let default_route =   api_obj['api_route']['default_routes']['indicator']
      fetch(default_route + acRoute, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      })
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const responseData = isJson && (await response.json());
          if (response.ok) {
            setSuggestions(responseData.result);
            setAcLoading(false);
          } else {
            const error = response.statusText;
            return Promise.reject(error);
          }
        })
      
    }
    // eslint-disable-next-line
  }, []);

  let suggestions = [];
  if (suggestion) {
    suggestions = suggestion.map((element) => {
      return element[acKey].toString();
    });
  }

  const onChange = (e) => {
    const userInput = e.target.value;
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setInput(e.target.innerText);
    handleChange(index, e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  let onBlur =
    props.onBlur ||
    function onBlurFunction() {
      handleChange(index, input);
      setFilteredSuggestions([]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length && input.length > 5 ? (
      <ul className="suggestions" key={acKey}>
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          return (
            <li
              key={suggestion + "_" + index}
              className={className}
              onMouseDown={(event) => {
                event.preventDefault();
                onClick(event);
              }}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>Nenhuma sugestão encontrada</em>
      </div>
    );
  };

  return (
    <div className={brInputClassName}>
      <label htmlFor={labelFor}>{labelText}</label>
      <input
        autoComplete="off"
        id={inputId}
        type={inputType}
        placeholder={inputPlaceholder}
        value={isAutocomplete ? input : inputValue}
        onChange={isAutocomplete ? onChange : handleChange}
        onBlur={onBlur}
      />

      {hasButton && (
        <button
          className={buttonClassName}
          type={buttonType}
          aria-label={buttonArialLabel}
          onClick={handleClick}
        >
          <i className={buttonIcon} aria-hidden="true"></i>
        </button>
      )}
      {showMessage && (
        <>
          <div className="sized-box__input" />
          <span className={`feedback ${feedBackType}`} role="alert">
            <i className={messageIcon} aria-hidden="true"></i>
            {messageText}
          </span>
        </>
      )}
      {hasToolTip && <p>{toolTipText}</p>}
      {isAutocomplete && showSuggestions && input && (
        <SuggestionsListComponent />
      )}
    </div>
  );
}

export default Input;
