/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { useState, useContext, useEffect } from "react";
import Card from "../../../ds-gov/Card";
import Button from "../../../ds-gov/Button";
import "./personalizationComponent.css";
import DescriptionCardModal from "../../modals/filter-modal";
import Select from "../../../ds-gov/Select";
import UserContext from "../../../../Store/user-context";

function PersonalizationCard(props) {
  let availableIndicators = props.availableIndicators || [];
  let id = props.id || 0;
  let key = props.keyValue || 0;
  let index = props.index || 0;
  let indicators = props.indicators || {};
  let setIndicators = props.setIndicators || function () { };
  let indicatorLength = props.len || -1;
  let setShouldSave = props.setShouldSave || function () { };
  const [isOpenModal, setIsOpenModal] = useState(false);
  const userContext = useContext(UserContext);
  const [options, setOptions] = useState([]);
  const [lastId, setLastId] = useState(id);
  let idName;
  if (lastId > 0){
    let ind = getIndicatorById(lastId);
    if(ind !== undefined) idName = ind["indicator_name"];

  } 
  
  const [indicatorName, setIndicatorName] = useState(idName || "");
  function openModal() {
    setIsOpenModal(!isOpenModal);
  }

  function getIndicatorById(id) {
    for (let i = 0; i < availableIndicators.length; i++) {
      const indicator = availableIndicators[i];
      if (indicator.id === id) return indicator;
    }
  }

  function validateIndicators() {
    for (let i = 0; i < indicators.length; i++) {
      const element = indicators[i];
      for (let j = 0; j < element.length; j++) {
        const id = element[j];
        if (id === 0 || id === undefined) {
          setShouldSave(false);
        }
      }
    }
  }

  useEffect(() => {
    validateIndicators();
    // eslint-disable-next-line
  }, [indicators]);

  function onSave() {
    setIsOpenModal(!isOpenModal);
    let newIndicators = [...indicators];
    let optionChecked = options.map((option) => {
      if (option.checked === true) return option;
      return undefined;
    });
    optionChecked = optionChecked.filter((e) => e !== undefined);
    if (optionChecked.length > 0) {
      optionChecked = optionChecked[0];
      let name = getIndicatorById(optionChecked.id)["indicator_name"];
      for (let i = 0; i < newIndicators.length; i++) {
        const indicator = newIndicators[i];
        for (let j = 0; j < indicator.length; j++) {
          if (i === key && j === index) {
            newIndicators[i][j] = optionChecked.id;
          }
        }
      }
      setShouldSave(true);
      setLastId(optionChecked.id);
      setIndicators(newIndicators);
      setIndicatorName(name);
    }
  }

  function splitCard(key) {
    let newIndicators = [];
    let value = [];
    newIndicators = indicators.map((indicator, k) => {
      if (k === key) {
        value = [...indicator, indicator[0]];
        return value;
      }
      return indicator;
    });
    setShouldSave(true);
    setIndicators(newIndicators);
  }

  function lineCard(key, index) {
    let newIndicators = [];
    let value = [];
    newIndicators = indicators.map((indicator, k) => {
      if (k === key) {
        value = [indicator[index]];
        return value;
      }
      return indicator;
    });
    setShouldSave(true);
    setIndicators(newIndicators);
  }

  function skipCard(key, index) {
    let newIndicators = [];
    newIndicators = indicators.map((indicator, k) => {
      return indicator.map((id, i) => {
        if (k === key && i === index) {
          return null;
        } else return id;
      });
    });
    setShouldSave(true);
    setIndicatorName("empty");
    setIndicators(newIndicators);
  }

  function resetCard(key, index) {
    let newIndicators = [];
    newIndicators = indicators.map((indicator, k) => {
      // eslint-disable-next-line
      return indicator.map((id, i) => {
        if (k === key && i === index) {
          if (lastId > 0) return lastId;
        } else return id;
      });
    });
    if (lastId > 0) {
      setIndicatorName(getIndicatorById(lastId)["indicator_name"]);
      setShouldSave(true);
    }
    else setIndicatorName("");
    setIndicators(newIndicators);
  }

  function addIndicator() {
    setIsOpenModal(!isOpenModal);
    let opt = [];
    for (let i = 0; i < availableIndicators.length; i++) {
      opt.push({
        id: availableIndicators[i].id,
        name: availableIndicators[i].indicator_name,
        checked: false,
      });
    }
    setOptions(opt);
  }

  function removeIndicator(key) {
    let newIndicators = [...indicators];
    newIndicators.splice(key, 1);
    setShouldSave(true);
    setIndicators(newIndicators);
  }

  function handleChangeOptions(index) {
    let newOptions = [];
    for (let i = 0; i < availableIndicators.length; i++) {
      newOptions.push({
        id: availableIndicators[i].id,
        name: availableIndicators[i].indicator_name,
        checked: false,
      });
    }
    newOptions[index].checked = !newOptions[index].checked;
    setOptions(newOptions);
  }

  if (id > -1) {
    return (
      <div className={`${userContext.contrast}personalization-card-outer`}>
        <Card
          headerContent={
            <div className={`personalization-header-content${userContext.contrast}`} key={index + key}>
              <div className="invisible_header_div"></div>
              <div className="header-card-title">
                <p>{indicatorName || "Nenhum indicador foi selecionado"}</p>
              </div>
              {indicators[key].length !== undefined &&
                indicators[key].length === 1 ? (
                <div className="header-button">
                  <Button
                    icon="fas fa-trash"
                    bground="secondary"
                    psize="small"
                    circle="circle"
                    onclick={() => {
                      removeIndicator(key);
                    }}
                    inverted={userContext.contrast === "contrast" && "inverted"}
                  />
                </div>
              ) : (
                id > 0 ? (
                  <div className="header-button">
                    <Button
                      icon="fas fa-trash"
                      bground="secondary"
                      psize="small"
                      circle="circle"
                      onclick={() => {
                        skipCard(key, index);
                      }}
                      inverted={userContext.contrast === "contrast" && "inverted"}
                    />
                  </div>
                ) : (
                  <div> </div>
                )
              )}
            </div>
          }
          cardContrast={userContext.contrast}
          cardType="complete"
          content={
            <div className={`personalization-content${userContext.contrast}`} key={index + key}>
              <DescriptionCardModal
                open={isOpenModal}
                onHandle={openModal}
                label="Salvar"
                onSave={onSave}
                description={
                  <div>
                    <Select
                      constrast={userContext.contrast}
                      options={options}
                      handleChange={handleChangeOptions}
                      placeholder="Escolha um indicador"
                      label="Indicadores"
                    />
                  </div>
                }
              />
              <Button
                bground="secondary"
                label="Adicionar Indicador"
                psize="small"
                onclick={() => {
                  addIndicator();
                }}
                inverted={userContext.contrast === "contrast" && "inverted"}
              />
              <Button
                bground="secondary"
                label={indicatorLength <= 1 ? "Dividir ao Meio" : "Linha Única"}
                psize="small"
                onclick={() => {
                  indicatorLength <= 1 ? splitCard(key) : lineCard(key, index);
                }}
                inverted={userContext.contrast === "contrast" && "inverted"}
              />
              {id > 0 ? (
                <div> </div>
              ) : (
                <Button
                  bground="secondary"
                  label={indicatorLength <= 1 ? "Pular Linha" : "Pular Espaço"}
                  psize="small"
                  onclick={() => {
                    skipCard(key, index);
                  }}
                  inverted={userContext.contrast === "contrast" && "inverted"}
                />
              )}

            </div>
          }
        />
      </div>
    );
  } else {
    return (
      <div className={`${userContext.contrast}personalization-card-outer`}>
        <Card
          cardContrast={userContext.contrast}
          cardType="complete"
          headerContent={
            <>
              <div className="invisible_header_div"></div>
              <div className={`header-card-title${userContext.contrast}`} style={{paddingTop: "1.8vh", paddingBottom: "1.8vh"}}>
                <p>{"Indicador vazio"}</p>
              </div>
            </>
          }
          content={
            <div className={`personalization-content${userContext.contrast}`} key={index + key}>
              <Button
                bground="secondary"
                label="Redefinir"
                psize="small"
                onclick={() => {
                  resetCard(key, index);
                }}
                inverted={userContext.contrast === "contrast" && "inverted"}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default PersonalizationCard;
