/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useEffect, useState } from "react";
import Layout from "./Components/app/layout/layout";
import "./App.css";
import "@govbr/dsgov/dist/dsgov.min.css";
import Routes from "./Routes/routes";
import LoginModal from "./Components/app/modals/login-modal";
import SignupModal from "./Components/app/modals/signup-modal";
import UserContext from "./Store/user-context";
import ForgotPassModal from "./Components/app/modals/forgot-pass-modal";
import ResetPassModal from "./Components/app/modals/reset-pass-modal";
import * as apiFunctions from "./Store/apiFunctions";
import api_configuration from "./api_configuration";
import DescriptionCardModal from "./Components/app/modals/description-modal";

function App() {
  const userContext = useContext(UserContext);
  const [token, setToken] = useState(null);
  const [accessibility, setAccessibility] = useState(false);
  let refreshToken = null;
  let tokenObj = {};

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(JSON.parse(localStorage.getItem("token"))["token"]);
    }
    userContext.toggleAccessibility()
    // eslint-disable-next-line
  }, []);

  function expirationDate() {
    var expire = new Date();
    expire.setDate(expire.getDate() + 1);
    return expire;
  }

  window.onload = function () {
    /* get public dashboard when window load */
    userContext.getPublicDashboard();
    /* if the token expired, you should get a new token */
    if (token) {
      refreshToken = JSON.parse(localStorage.getItem("token"))["refreshToken"];
      var today = new Date();
      var exp = new Date(JSON.parse(localStorage.getItem("token"))["expiry"]);
      if (exp < today) {
        if (localStorage.getItem("rememberMe") === "false") {
          localStorage.clear();
          window.location.reload();
        } else {
          /* in order to generate a new token, it needs the refreshToken */
          apiFunctions.Post(
            api_configuration.api_route.refresh_token_api,
            { refreshToken: refreshToken },
            setToken
          );
          /*save the new token object in the local storage */
          tokenObj = {
            token: token,
            refreshToken: refreshToken,
            expiry: expirationDate(), // date + 1 day
          };
          localStorage.setItem("token", JSON.stringify(tokenObj));
        }
      }
    }
  };

  window.onbeforeunload = function () {
    if (token) {
      var today = new Date();
      var exp = new Date(JSON.parse(localStorage.getItem("token"))["expiry"]);
      if (exp < today && localStorage.getItem("rememberMe") === "true") {
        let tokenObj = {
          token: token,
          refreshToken: refreshToken,
          expiry: expirationDate(), // date + 1 day
        };
        localStorage.setItem("token", JSON.stringify(tokenObj));
      }
    }
  };

  function validateToken(token) {
    if (token !== null || token !== "") return true;
    return false;
  }

  return (
    <div>
      <LoginModal
        open={validateToken(token) ? userContext.openLoginModal : false}
        onHandle={() => {
          userContext.toggleLoginModal();
          userContext.toggleAccessibility();
        }}
        onOpenSignupModal={() => {
          userContext.toggleSignupModal();
        }}
        onOpenForgotPassModal={() => {
          userContext.toggleForgotPassModal();
        }}
      />
      <SignupModal
        open={validateToken(token) ? userContext.openSignupModal : false}
        onHandle={() => {
          userContext.closeSignupModal();
          userContext.toggleAccessibility();
        }}
      />
      <ForgotPassModal
        open={userContext.openForgotPassModal}
        onHandle={() => {
          userContext.closeForgotPassModal();
          userContext.toggleAccessibility();
        }}
        onGoBackForgotPassModal={userContext.goBackForgotPassModal}
      />
      <ResetPassModal
        open={validateToken(token) ? userContext.openResetPassModal : false}
        onHandle={() => {
          userContext.closeResetPassModal();
          userContext.toggleAccessibility();
        }}
      />

      <DescriptionCardModal
        open={accessibility}
        onHandle={() => setAccessibility(!accessibility)}
        title="Acessibilidade"
        description={
          <div style={{ fontWeight: "normal", fontSize: "medium" }}>
            <p>
              O termo acessibilidade significa incluir a pessoa com deficiência
              na participação de atividades como o uso de produtos, serviços e
              informações. Alguns exemplos são os prédios com rampas de acesso e
              os banheiros adaptados para pessoas com deficiência.
            </p>
            <br />
            <p>
              Na internet, acessibilidade refere-se principalmente às
              recomendações do WCAG (World Content Accessibility Guide) do W3C
              e, no caso do Governo Brasileiro, ao e-MAG (Modelo de
              Acessibilidade em Governo Eletrônico). O e-MAG está alinhado às
              recomendações internacionais e estabelece padrões de comportamento
              acessíveis para sites governamentais.
            </p>
            <br />
            <p>
              Na parte superior do portal existe uma barra de acessibilidade
              onde se encontram atalhos de navegação padronizados e a opção para
              alterar o contraste. Essas ferramentas estão disponíveis em todas
              as páginas do portal.
            </p>
            <br />
            <p>Os padrões de atalhos do governo federal são:</p>
            <br />
            <ul>
              <li>
                Teclando-se Alt + 1 em qualquer página do portal, chega-se
                diretamente ao começo do conteúdo principal da página.
              </li>
              <li>
                Teclando-se Alt + 2 em qualquer página do portal, chega-se
                diretamente ao início do menu principal.
              </li>
              <li>
                Teclando-se Alt + 3 em qualquer página do portal, chega-se
                diretamente ao rodapé do site.
              </li>
            </ul>
            <br />
            <p>
              Esses atalhos valem para o navegador Chrome, mas existem algumas
              variações para outros navegadores.
            </p>
            <br />
            <p>
              Ao utilizar o Internet Explorer, por exemplo, é preciso apertar o
              botão Enter do seu teclado após uma das combinações acima.
              Portanto, para chegar ao rodapé do site é preciso pressionar Alt +
              3 e depois Enter.
            </p>
            <br />
            <p>
              No caso do Firefox, tecle simultaneamente Alt + número. 
              Sendo Firefox no Mac OS, em vez de Alt + Shift + número, 
              tecle simultaneamente Ctrl + Alt + número.
            </p>
          </div>
        }
      />

      <Layout
        loginConfirm={userContext.loginConfirm}
        handleLogin={userContext.toggleLoginModal}
        handleContrast={userContext.changeContrast}
        handleAccessibility={() => setAccessibility(!accessibility)}
        contrast={userContext.contrast}
      >
        <Routes />
      </Layout>
    </div>
  );
}

export default App;
