/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import React from 'react';
import "./custom-tooltip.css";

const CustomTooltip = ({ active, payload, label, contrast }) => {
  if (active && payload && payload.length) {
    return (
      <div className={`card_container_${contrast}`}>
        <p style={{ textAlign: "center", zIndex: "999" }}>{label}</p>
        <div  className='text_tooltip'>
          {
            payload.map((data, index) => {
              return <p key={data.dataKey} style={{ color: `${data.fill}` }}>{`${data.dataKey}: ${data.value}`}</p>
            })
          }
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip
