/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

var env = process.env.NODE_ENV
const template = {
  "development": {
    "system": "http://painelpnlddev.c3sl.ufpr.br/system/",
    "indicator": "http://painelpnlddev.c3sl.ufpr.br/datamart/api/v1/",
  },
  "production": {
    "system": "//painelpnldhomologa.c3sl.ufpr.br/system/",
    "indicator": "//painelpnldhomologa.c3sl.ufpr.br/datamart/api/v1/",
  },
};

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SERVER_ENV === "dev"){
  env = 'development'
}

// Descomentar a inha 34 antes do commit e apagar a linha 35
const base_system_api = template[env]["system"];  
//const base_system_api = "http://localhost:3000/";
const base_indicator_api = template[env]["indicator"];
const public_dashboard_filter =
  '?filter={"include":[{"relation":"items_id","scope":{"include":[{"relation":"indicator","scope":{"include":[{"relation":"dimensions"},{"relation":"filters"}]}}]}}]}';
const group_filter = '?filter={"include":[{"relation":"indicators"}]}';
const indicator_filter = '?filter={"include":[{"relation": "filters","relation": "dimensions"},{"relation":"filters"}]}';
const api_route = {
  login_api: base_system_api + "users/refreshLogin",
  signup_api: base_system_api + "signup",
  whoAmI_api: base_system_api + "whoAmI",
  user_api: base_system_api + "users/",
  user_api_filter:
    '?filter={"include":[{"relation":"userNotes"},{"relation":"roles"},{"relation":"dashboards","scope":{"include":[{"relation":"items_id","scope":{"include":[{"relation":"indicator","scope":{"include":[{"relation":"dimensions"},{"relation":"filters"}]}}]}}]}},{"relation":"indicators_groups","scope":{"include":[{"relation":"indicators","scope":{"include":[{"relation":"dimensions"},{"relation":"filters"}]}}]}}]}',
  rolesFilter:
    '?filter={"include":[{"relation":"userNotes"},{"relation": "roles"}]}',
  indicator_api: base_indicator_api,
  public_dashboard_api:
    base_system_api + "dashboards/public" + public_dashboard_filter,
  new_dashboard_api: base_system_api + "dashboards", // post
  dashboard_user_api: base_system_api + "dashboard-users",
  dashboard_items_api: base_system_api + "dashboard-items/multiple",
  dashboard_items_multiple_api: (id) => {
    return base_system_api + `dashboards/${id}/dashboard-items/multiple`;
  },
  single_indicator_api: (id) => {
    return base_system_api + `indicators/${id}`
  },
  roles_api: base_system_api + "roles/",
  get_users_api: base_system_api + "users",
  groups_api: base_system_api + "groups/",
  get_groups_api: base_system_api + "groups" + group_filter,
  users_group_api: base_system_api + "user-groups/",
  users_group_multiple: base_system_api  + "user-groups/multiple",
  user_notes_api: base_system_api + "user-notes/",
  basic_indicators_api: base_system_api + "indicators/",
  indicators_api: base_system_api + "indicators" + indicator_filter,
  indicator_filters_multiple_api: base_system_api + "indicator-filters-multiple/",
  indicator_dims_multiple_api: base_system_api + "indicator-dimensions-multiple/",
  filters_api: base_system_api + "filters/",
  dims_api: base_system_api + "dimensions/",
  sync_api: base_system_api + "syncDb/",
  reset_tickets_api: base_system_api + "reset-tickets/",
  reset_password_api: base_system_api + "reset-tickets/reset-password/",
  refresh_token_api: base_system_api + "refresh/",
  group_indicators_multiple: base_system_api + "g-indicator/multiple",
  user_self_api: base_system_api + "/users/self/",
  default_routes: {
    system: base_system_api,
    indicator: base_indicator_api
  },
  region_api: base_indicator_api + "regiao",
  mesoregion_api: base_indicator_api + "/mesorregiao/",
  uf_api: base_indicator_api + "/estado/",
  city_api: base_indicator_api + "/municipio/"
};
const api_obj = { api_route };

export default api_obj;
