/*Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api_configuration from "../api_configuration";
import * as apiFunctions from "./apiFunctions";

const UserContext = createContext({
  contrast: "",
  changeContrast: () => { },
  openLoginModal: false,
  toggleLoginModal: () => { },
  openSignupModal: false,
  toggleAccessibility: () => { },
  closeAccessibility: () => { },
  toggleSignupModal: () => { },
  closeSignupModal: () => { },
  openForgotPassModal: false,
  toggleForgotPassModal: () => { },
  closeForgotPassModal: () => { },
  goBackForgotPassModal: () => { },
  openResetPassModal: false,
  closeResetPassModal: () => { },
  getUser: () => { },
  handleLoginTry: () => { },
  handleSignUpTry: () => { },
  loginConfirm: () => { },
  onHandleLogOut: () => { },
  getPublicDashboard: () => { },
  getRoleName: () => { },
  redirectTo: () => { },
  setBreadLocation: () => { },
  getBreadLocation: () => { },
});

export function UserContextProvider(props) {
  const [contrast, setContrast] = useState("");
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const [requestUser, setRequestUser] = useState(undefined);
  const [onHandle, setOnHandle] = useState(function () { });
  const [email, setEmail] = useState(sessionStorage.getItem("email") || "");
  const [passwordField, setPasswordField] = useState(
    sessionStorage.getItem("password") || ""
  );
  const [PublicDbRequest, setPublicDbRequest] = useState(undefined);
  const history = useHistory();
  const [crumb, setCrumb] = useState([]);

  function setBreadLocation(location) {
    setCrumb(location)
  }

  function getBreadLocation() {
    return crumb
  }

  useEffect(() => {
    const getContrast = localStorage.getItem("contrast");
    setContrast(getContrast || "");
  }, []);

  useEffect(() => {
    if (PublicDbRequest !== undefined) {
      fetch(api_configuration.api_route.public_dashboard_api, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const responseData = isJson && (await response.json());
          if (response.ok) {
            localStorage.setItem(
              "publicDashboard",
              JSON.stringify(responseData)
            );
          } else {
            const error = response.statusText;
            return Promise.reject(error);
          }
        })
        .catch((error) => {
        });
    }
  }, [PublicDbRequest]);

  const getRoleName = (setRoleName, user) => {
    fetch(api_configuration.api_route.roles_api + user.role_id, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")).token,
      },
    })
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const responseData = isJson && (await response.json());
        if (response.ok) {
          setRoleName(responseData.role_name);
        } else {
          const error = response.statusText;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
      });
  };

  const handleSignUpTry = (userModel, onHandle) => {
    apiFunctions.PostUnauth(
      api_configuration.api_route.signup_api,
      userModel,
      onHandle
    );
  };

  const getUser = (id) => {
    apiFunctions.getUser(
      api_configuration.api_route.user_api +
      id +
      api_configuration.api_route.user_api_filter
    );
  };

  const handleChangeContrast = () => {
    let newContrast = contrast === "contrast" ? "" : "contrast";
    localStorage.setItem("contrast", newContrast);
    setContrast(newContrast);
  };

  useEffect(() => {
    if (requestUser !== undefined) {
      apiFunctions.userRequest(
        api_configuration.api_route.whoAmI_api,
        onHandle
      );
    }
    // eslint-disable-next-line
  }, [requestUser]);

  const handleLoginTry = (
    email,
    password,
    rememberMe,
    onHandle,
    handleError
  ) => {
    setOnHandle(() => onHandle);
    setPasswordField(password);
    setEmail(email);
    let body = { email: email, password: password };
    apiFunctions.login(
      api_configuration.api_route.login_api,
      body,
      rememberMe,
      setRequestUser,
      requestUser,
      handleError
    );
  };

  const onHandleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const loginConfirm = () => {
    if (email !== "" && passwordField !== "") return 1;
    return 0;
  };

  const toggleLoginModal = () => {
    setOpenLoginModal(!openLoginModal);
  };

  const getPublicDashboard = () => {
    setPublicDbRequest(true);
  };

  const toggleSignupModal = () => {
    setOpenSignupModal(true);
    toggleLoginModal();
  };

  const closeSignupModal = () => {
    setOpenSignupModal(false);
  };

  const toggleAccessibility = () => {

    localStorage.setItem("accessibility", true)
  }

  const closeAccessibility = () => {
    localStorage.setItem("accessibility", false)
  }


  const toggleForgotPassModal = () => {
    setOpenForgotPassModal(true);
    toggleLoginModal();
  };

  const closeForgotPassModal = () => {
    setOpenForgotPassModal(false);
  };

  const goBackForgotPassModal = () => {
    closeForgotPassModal();
    toggleLoginModal();
  };

  const closeResetPassModal = () => {
    setOpenResetPassModal(false);
  };

  const redirectTo = (page) => {
    history.push(page);
  };

  const context = {
    contrast: contrast,
    changeContrast: handleChangeContrast,
    openLoginModal: openLoginModal,
    toggleLoginModal: toggleLoginModal,
    openSignupModal: openSignupModal,
    toggleSignupModal: toggleSignupModal,
    closeSignupModal: closeSignupModal,
    openForgotPassModal: openForgotPassModal,
    toggleForgotPassModal: toggleForgotPassModal,
    closeForgotPassModal: closeForgotPassModal,
    goBackForgotPassModal: goBackForgotPassModal,
    openResetPassModal: openResetPassModal,
    closeResetPassModal: closeResetPassModal,
    getUser: getUser,
    handleLoginTry: handleLoginTry,
    handleSignUpTry: handleSignUpTry,
    loginConfirm: loginConfirm,
    onHandleLogOut: onHandleLogOut,
    toggleAccessibility: toggleAccessibility,
    closeAccessibility: closeAccessibility,
    getPublicDashboard: getPublicDashboard,
    getRoleName: getRoleName,
    redirectTo: redirectTo,
    setBreadLocation: setBreadLocation,
    getBreadLocation: getBreadLocation,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
