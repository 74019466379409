/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import "./dashboard-card.css";
import Button from "../../../ds-gov/Button";
import UserContext from "../../../../Store/user-context";
import NotificationContext from "../../../../Store/notification-store";
import DescriptionModal from "../../modals/description-modal";
import FilterModal from "../../modals/filter-modal";
import axios from "axios";
import PlotGraph from "../../../../helpers/dashboard";
import BuildFilters from "../../../../helpers/dashboard-filter";
import api_configuration from "../../../../api_configuration";
import lodash from "lodash";
import { CircularProgress, Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";

/* Params
 * id: number */

function DashboardCard(props) {
  let { id, fullPage, availableIndicators, savedFilter, savedKeys, regions, mesoregions, uf, cities, setUrlEstado, setUrlMesoregion, setUrlCities } = props;
  let indicator = getIndicatorById(id);
  const history = useHistory();
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [keys, setKeys] = useState(savedKeys ? savedKeys : undefined);
  const [mesoregionsId, setMesoregionsId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acLoading, setAcLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isBeingResized, setIsBeingResized] = useState(false);
  const [chartType, setChartType] = useState(getDefaultValue(indicator["indicator_presentation_shape"]));
  const [filterFlag, setFilterFlag] = useState(false);
  let api_route = api_configuration.api_route.indicator_api;
  let urlBase = api_route + indicator["indicator_route"];
  const [API_URL, setAPI] = useState(urlBase);
  const [downloadUrl, setDownloadUrl] = useState(urlBase + "?format=csv");
  const [enterFlag, setEnterFlag] = useState(false);

  const [filters, setFilters] = useState(
    savedFilter && savedFilter[id].length > 0
      ? savedFilter[id]
      : indicator["filters"] !== undefined ? indicator["filters"].map((filter) => {
        return {
          ...filter,
          value: "",
        };
      }) : []
  );

  function getIndicatorById(id) {
    for (let i = 0; i < availableIndicators.length; i++) {
      const indicator = availableIndicators[i];
      if (indicator.id === id) return indicator;
    }
  }

  // eslint-disable-next-line
  const reset = useCallback(
    lodash.debounce(() => {
      setIsBeingResized(false);
    }, 200),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      setIsBeingResized(true);
      reset();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [setIsBeingResized]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      axios({
        url: API_URL,
      })
        .then((response) => {
          if (response.statusText === "OK") return response.data.result;
          else throw new Error("Error");
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          setError(true);
          notificationContext.showNotification({
            title: "Erro",
            message: "Não foi possível carregar os dados",
            status: "error",
            position: "top-right",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, [API_URL]);

  useEffect(() => {
    buildURL();

    // eslint-disable-next-line
  }, [enterFlag]);

  function buildURL() {
    let filterKey = "";
    let dims = "";
    let newURL = "";
    let url_dims = "";
    let url_filters = "";
    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      if (filter.value !== "" && filter.value.length > 0) {
        filterKey += `${filter.filter_api_callable}:[`;
        if (filter.filter_api_callable === "mesoregion") {
          for (let i = 0; i < filter.value.length; i++) {
            const item = filter.value[i];
            let codigoString = item.toString();
            codigoString = codigoString.substring(2, 4);
            filterKey += `"${codigoString}",`;
          }
        } else {
          if (typeof filter.value === "string") {
            filterKey += `"${filter.value}",`;
          } else {
            for (let i = 0; i < filter.value.length; i++) {
              const item = filter.value[i];
              filterKey += `"${item}",`;
            }
          }
        }
        filterKey = filterKey.substring(0, filterKey.length - 1);
        filterKey += `],`;
        dims += `${filter.filter_api_callable},`;
      }
    }
    url_dims = dims.substring(0, dims.length - 1);
    url_filters = filterKey.substring(0, filterKey.length - 1);
    newURL = urlBase + "?dims=" + url_dims + "&filters=" + url_filters;
    setAPI(newURL);
    setDownloadUrl(newURL + "&format=csv");
    return newURL;
  }

  function clearFilters() {
    const newFilters = [...filters];
    for (let index = 0; index < newFilters.length; index++) {
      newFilters[index].value = "";
    }
    setFilters(newFilters);
    setAPI(urlBase);
  }

  function handleChangeMesoregions(newMesoregionsChecked) {
    setMesoregionsId(newMesoregionsChecked);
  }

  function handleChangeKeys(keys) {
    setKeys(keys);
  }

  function handleDescriptionModal() {
    setOpenModal((prevState) => !prevState);
  }

  function handleFilterModal() {
    setOpenFilter((prevState) => !prevState);
  }

  useEffect(() => {
    buildURL();
    let newFilterHash = JSON.parse(
      localStorage.getItem("savedPersonalizedHash")
    );
    newFilterHash[id] = filters;
    localStorage.setItem(
      "savedPersonalizedHash",
      JSON.stringify(newFilterHash)
    );
    setFilterFlag(false);
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.value.length > 0) setFilterFlag(true);
    }
    // eslint-disable-next-line
  }, [filters]);

  function redirectTo(path) {
    history.push({
      pathname: path,
      state: {
        id: id,
        availableIndicators: availableIndicators,
        savedFilter: JSON.parse(localStorage.getItem("savedPersonalizedHash")),
        savedKeys: keys,
      },
    });
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && fullPage) {
        event.preventDefault();
        redirectTo("/dashboard");
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  function myFunction() {
    document.getElementById("myDropdown" + id).classList.toggle("show");
  }

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches(`.dropbtn_${userContext.contrast}`)) {
      let dropdowns = document.getElementsByClassName(`dropdown_content_${userContext.contrast}`);
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  function convertDate(dataString) {
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    const diasSemana = [
      'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'
    ];

    const data = new Date(dataString);
    const diaSemana = diasSemana[data.getDay()]
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const ano = data.getFullYear();

    return `${diaSemana}, ${dia} de ${mes} de ${ano}`;
  }



  let filterSpan = (
    <>
      <div style={{ top: "-3px", position: "relative" }}>
        <div className="span_filter">Filtro: </div>
        {filterFlag ? (
          <div className="activated_filter">
            Ativado
          </div>
        ) : (
          <div className="disable_filter">
            Desativado
          </div>
        )}
      </div>
    </>
  );

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && fullPage) {
        event.preventDefault();
        redirectTo("/personalized_dashboard");
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  ;

  // talvez mude se decidirmos que os tipos de grafico serao dinamicos
  function getDefaultValue(shape) {
    switch (shape) {
      case "line":
        return 1;
      case "bar":
        return 2;
      case "table":
        return 3;
      case "stackedbar":
        return 4;
      case "stackedline":
        setChartType(5);
        break;
      default:
        return 2;
    }
  }
  const [stackedBar, setStackedBar] = useState(false);


  useEffect(() => {
    switch (indicator["indicator_presentation_shape"]) {
      case "line":
        setChartType(1);
        break;
      case "bar":
        setChartType(2);
        break;
      case "table":
        setChartType(3);
        break;
      case "stackedbar":
        setStackedBar(true);
        setChartType(4);
        break;
      default:
        setChartType(2);
        break;
    }
    // eslint-disable-next-line
  }, [indicator["indicator_presentation_shape"]]);


  return (
    <div style={fullPage ? { margin: "1%" } : {}}>
      <DescriptionModal
        open={openModal}
        onHandle={handleDescriptionModal}
        title={`Indicador ${indicator["indicator_code"]}: ${indicator["indicator_name"]}`}
        description={indicator["indicator_description"]}
      />
      <FilterModal
        open={openFilter}
        onHandle={() => {
          userContext.toggleAccessibility();
          handleFilterModal();
        }}
        title={`Filtros para o indicador: ${indicator["indicator_name"]}`}
        hasFooter={false}
        description={
          <BuildFilters
            filters={filters}
            setFilters={setFilters}
            handleChangeKeys={handleChangeKeys}
            handleChangeMesoregionsChecked={handleChangeMesoregions}
            contrast={userContext.contrast}
            filtersState={filters}
            setAcLoading={setAcLoading}
            isLoading={acLoading}
            onHandle={handleFilterModal}
            onSave={buildURL}
            enterFlag={enterFlag}
            setEnterFlag={setEnterFlag}
            newRegions={regions}
            newMesoregions={mesoregions}
            newUf={uf}
            newCities={cities}
            setUrlUf={setUrlEstado}
            setUrlMesoregion={setUrlMesoregion}
            setUrlCities={setUrlCities}
          />
        }
      />
      <div className={`card_container_${userContext.contrast}`}>
        <div className="card__header">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {fullPage && (
              <Button
                pstate=""
                circle="circle"
                onclick={() => redirectTo("/dashboard")}
                psize="small"
                icon="fas fa-arrow-left"
                inverted={userContext.contrast === "contrast" && "inverted"}
                tooltip={"Voltar"}
              />
            )}
          </div>
          <div className="card__header__title">
            <div className={`header_name_${userContext.contrast}`}>Indicador {indicator["indicator_code"]}</div>
            <div className={`indicator_n_${userContext.contrast}`}>{indicator["indicator_name"]}</div>
          </div>

          <div
            style={{ flex: 0.4, flexDirection: "row" }}
            className="card__header__description"
          >
            <Button
              pstate=""
              circle="circle"
              onclick={() =>
                !fullPage
                  ? redirectTo("/FullPageIndicator")
                  : redirectTo("/dashboard")
              }
              psize="small"
              icon={!fullPage ? "fas fa-expand" : "fas fa-compress"}
              inverted={userContext.contrast === "contrast" && "inverted"}
              tooltip={fullPage ? "Minimizar" : "Maximizar"}
            />

            <Button
              pstate=""
              circle="circle"
              onclick={() => {
                handleFilterModal();
                userContext.closeAccessibility();
              }}
              psize="small"
              tooltip="Filtrar"
              icon="fas fa-filter"
              inverted={userContext.contrast === "contrast" && "inverted"}
            />
            <div className="dropdown" >
              <button onClick={myFunction} className={`dropbtn_${userContext.contrast} fas fa-ellipsis-v`}></button>
              <div id={"myDropdown" + id} className={`dropdown_content_${userContext.contrast}`}>
                <div className="dropdown_card" >
                  <div className={`dropdown_options_${userContext.contrast}`} >
                    <a style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} href onClick={handleDescriptionModal} >
                      <i style={{ marginRight: "10px" }} className="fas fa-info"></i>
                      Informação
                    </a>
                  </div>

                  <div className={`dropdown_options_${userContext.contrast}`}  >
                    <a style={{ display: "flex", flexDirection: "row" }} href={downloadUrl} download >
                      <i style={{ marginRight: "10px" }} className="fas fa-cloud-download-alt"></i>
                      Download
                    </a>
                  </div>

                  <div className={`dropdown_options_${userContext.contrast}`}  >
                    <a style={{ display: "flex", flexDirection: "row" }} href onClick={clearFilters} >
                      <i style={{ marginRight: "2px" }} className="fas fa-trash-alt"></i>
                      Limpar filtros
                    </a>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="card__filter">
          {filterSpan}
          <div className="card__filter__invisible" />
          <div className="card__filter__button">
            {id !== 2 && id !== 5 && id !== 8 && (
              <div>
                <FormControl
                  style={{
                    minWidth: 120,
                    color: "#333333",
                  }}
                >
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    size="normal"
                    style={{
                      color: "#333333",
                      borderColor: "#1351b4",
                    }}
                  >
                    Gráfico
                  </InputLabel>
                  <Select
                    defaultValue={chartType}
                    inputProps={{
                      name: "Gráfico",
                      id: "selectedBox",
                    }}
                    onChange={(e) => setChartType(e.target.value)}
                    style={{
                      color: "#333333",
                    }}
                  >
                    <MenuItem
                      className="menu_item_div"
                      style={{
                        backgroundColor: userContext.contrast
                          ? "#071d41"
                          : "white",
                        color: userContext.contrast
                          ? "white"
                          : ""
                      }}
                      value={1}
                    >
                      Linha
                    </MenuItem>

                    <MenuItem
                      className="menu_item_div"
                      style={{
                        backgroundColor: userContext.contrast
                          ? "#071d41"
                          : "white",
                        color: userContext.contrast
                          ? "white"
                          : ""
                      }}
                      value={2}
                    >
                      Coluna
                    </MenuItem>
                    {stackedBar && (
                      <MenuItem
                        className="menu_item_div"
                        style={{
                          backgroundColor: userContext.contrast ? "#071d41" : "white",
                          color: userContext.contrast ? "white" : ""
                        }}
                        value={4}
                      >
                        Coluna Agrupada
                      </MenuItem>
                    )}
                    <MenuItem
                      className="menu_item_div"
                      style={{
                        backgroundColor: userContext.contrast
                          ? "#071d41"
                          : "white",
                        color: userContext.contrast
                          ? "white"
                          : ""
                      }}
                      value={3}
                    >
                      Tabela
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>

        {/* <hr /> */}
        <div style={{ height: "20px" }}></div>
        <div
          style={
            fullPage
              ? id === 2 || id === 5 || id === 8
                ? { minHeight: "10vh" }
                : { minHeight: "50vh" }
              : {}
          }
          className="card__content"
        >
          {error ? (
            "Erro ao carregar dados"
          ) : loading || isBeingResized ? (
            <CircularProgress />
          ) : (
            <PlotGraph
              label={indicator["label"]}
              id={JSON.stringify(indicator["id"])}
              chartType={chartType}
              isStackedBar={stackedBar}
              data={data}
              presentationShape={indicator["indicator_presentation_shape"]}
              keys={keys}
              mesoregionsId={mesoregionsId}
              contrast={userContext.contrast}
              fullPage={fullPage}
            />
          )}
        </div>
        <hr />
        <div className="card__footer">
          <div>
            <i>Fonte: {indicator["indicator_source"]}</i>
          </div>
          <div>
            <i style={{ fontStyle: "italic" }}>Última atualização:{" "}</i>
            {convertDate(new Date(indicator["last_update"]).toDateString())}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(DashboardCard);
