import React, { memo, useEffect, useState } from "react";
import DashboardCard from "./public-dashboard/dashboard-card";
import { useLocation } from "react-router-dom";
import * as apiFunctions from "../../../Store/apiFunctions";
import api_obj from "../../../api_configuration";
function FullPageIndicatorComponent() {
  const location = useLocation();
  const [regions, setRegions] = useState([])
  const [mesoregions, setMesoregions] = useState([])
  const [cities, setCities] = useState([])
  const [uf, setUf] = useState([])
  const [urlEstado, setUrlEstado] = useState("")
  const [urlMesoregion, setUrlMesoregion] = useState("")
  const [urlCities, setUrlCities] = useState("")

  useEffect(() => {
    apiFunctions.GetUnauth(api_obj.api_route.region_api, setRegions)
    if (urlMesoregion !== "") apiFunctions.GetUnauth(urlMesoregion, setMesoregions)
    if (urlCities !== "") apiFunctions.GetUnauth(urlCities, setCities)
    if (urlEstado !== "") apiFunctions.GetUnauth(urlEstado, setUf)
  }, [urlEstado, urlMesoregion, urlCities]);
  if (location.state) {
    return (
      <DashboardCard
        id={location.state.id}
        fullPage={true}
        availableIndicators={location.state.availableIndicators}
        savedFilter={location.state.savedFilter}
        savedKeys={location.state.savedKeys}
        regions={regions.result}
        mesoregions={mesoregions.result}
        cities={cities.result}
        uf={uf.result}
        setUrlEstado={setUrlEstado}
        setUrlMesoregion={setUrlMesoregion}
        setUrlCities={setUrlCities}
      />
    );
  } else {
    return <div> erro </div>;
  }
}

export default memo(FullPageIndicatorComponent);
