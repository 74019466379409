/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useEffect, useState } from "react";
import DashboardHelper from "../tests/DashboardHelper";
import DashboardCard from "./dashboard-card";
import "./Dashboard.css";
import UserContext from "../../../../Store/user-context";
import NotificationContext from "../../../../Store/notification-store";
import { useLocation } from "react-router-dom";
import * as apiFunctions from "../../../../Store/apiFunctions";
import api_obj from "../../../../api_configuration";
import Card from "../../../ds-gov/Card";

function Dashboard() {

  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [regions, setRegions] = useState([]);
  const [mesoregions, setMesoregions] = useState([]);
  const [cities, setCities] = useState([]);
  const [uf, setUf] = useState([]);
  const [urlEstado, setUrlEstado] = useState("");
  const [urlMesoregion, setUrlMesoregion] = useState("");
  const [urlCities, setUrlCities] = useState("");
  var dbh = new DashboardHelper();
  dbh.retrieve_indicators();
  let indicators = dbh.indicator_order;
  const publicDb = JSON.parse(localStorage.getItem("publicDashboard"));
  const location = useLocation();
  function getAvailiableIndicators() {
    let groupIndicators = [];
    for (let i = 0; i < publicDb.items_id.length; i++) {
      const ind = publicDb.items_id[i].indicator || [];
      groupIndicators.push(ind);
    }
    return groupIndicators;
  }

  useEffect(() => {
    let tam = localStorage.getItem('countFont');
    let classElements = document.getElementsByTagName("*");
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize = (parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) * 1.05 + 'px');
      }
      j += 1;
    }
    userContext.setBreadLocation([{ name: "Dashboard", href: "/dashboard_selection", clickable: true },
    { name: "Dashboard Público", href: "/dashboard", clickable: false }]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    apiFunctions.GetUnauth(api_obj.api_route.region_api, setRegions);
    if (urlMesoregion !== "") apiFunctions.GetUnauth(urlMesoregion, setMesoregions);
    if (urlCities !== "") apiFunctions.GetUnauth(urlCities, setCities);
    if (urlEstado !== "") apiFunctions.GetUnauth(urlEstado, setUf);
  }, [urlEstado, urlMesoregion, urlCities]);


  useEffect(() => {
    if (localStorage.getItem("savedHash") === null) getFilterHash();
    notificationContext.showNotification({
      title: "Aviso",
      message:
        "Alguns filtros podem demorar para carregar devido à grande quantidade de dados",
      status: "loading",
      position: "top-right",
    });
    // eslint-disable-next-line
  }, []);

  function getFilterHash() {
    let indicatorsId = []; // lista de ids de indicadores
    for (let i = 0; i < indicators.length; i++) {
      const element = indicators[i];
      for (let j = 0; j < element.length; j++) {
        const ind = element[j];
        indicatorsId.push(ind);
      }
    }
    let filterHash = {};
    for (let i = 0; i < indicatorsId.length; i++) {
      filterHash = {
        ...filterHash,
        [indicatorsId[i]]: [],
      };
    }
    localStorage.setItem("savedHash", JSON.stringify(filterHash));
  }


  return (
    localStorage.getItem("savedHash") && (
      <div className={`background_${userContext.contrast}`}>
        <div className="main__page">
          {indicators.length > 0 ? indicators.flatMap((elem, index) => (
            <div key={"indicators_" + index} className="row">
              {elem.flatMap((elems, key) =>
                elems !== null ? (
                  <div key={"items_" + key} className="row__inner">
                    <DashboardCard
                      id={elems}
                      availableIndicators={getAvailiableIndicators()}
                      savedFilter={
                        location.state ? location.state.savedFilter : undefined
                      }
                      savedKeys={
                        location.state ? location.state.savedKeys : undefined
                      }
                      regions={regions.result}
                      mesoregions={mesoregions.result}
                      cities={cities.result}
                      uf={uf.result}
                      setUrlEstado={setUrlEstado}
                      setUrlMesoregion={setUrlMesoregion}
                      setUrlCities={setUrlCities}
                    />
                  </div>
                ) : (
                  <div key={"items_" + key} className="row__invisible__div" />
                )
              )}
            </div>
          )) : <div style={{ margin: "50px" }}> <Card content={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ fontSize: "20px" }}>Desculpe, infelizmente não possuimos dados para exibir no momento! </p>
            </div>} /> </div>}
        </div>
      </div>
    )
  );
}

export default Dashboard;
