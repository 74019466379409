import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UserContext from '../../../Store/user-context';
import { useState, useContext } from 'react';

export default function BasicTable({ data }) {
    // eslint-disable-next-line
    const [keys, setKeys] = useState(getKeys())
    const userContext = useContext(UserContext);
    function getKeys() {
        return Object.keys(data[0])
    }
    return (
        <div style={{ minWidth: "98%", maxWidth: "98%", position:"absolute", alignItems:"center" }}>
        <TableContainer component={Paper} >
            <Table sx={{ maxWidth: "100%", backgroundColor: userContext.contrast ? "#071d41" : "white" }} aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell sx={{ backgroundColor: userContext.contrast ? "#0c326f" : (224, 224, 224) }}></TableCell>
                        {data.map((column) => (
                            <TableCell sx={{ backgroundColor: userContext.contrast ? "#0c326f" : (224, 224, 224), color: userContext.contrast ? "white" : "black" }}>
                                {column[keys[0]]}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((filters, index) => (
                        index > 0 &&
                        <>
                            <TableRow
                                key={keys[1]}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ color: userContext.contrast ? "white" : "black" }} >{filters}</TableCell>
                                {data.map((row, i) => (
                                    <>
                                        <TableCell align="right" scope="row" sx={{ color: userContext.contrast ? "white" : "black" }}>
                                            {row[keys[index]]}
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}

