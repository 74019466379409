/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Card from "../../../ds-gov/Card";
import Button from "../../../ds-gov/Button";
import PersonalizationHelper from "../tests/personalizationHelper";
import PersonalizationCard from "./personalizationCard";
import "./personalizationComponent.css";
import UserContext from "../../../../Store/user-context";
import api_configuration from "../../../../api_configuration";
import * as apiFunctions from "../../../../Store/apiFunctions";
import { useHistory } from "react-router-dom";
import NotificationContext from "../../../../Store/notification-store";

function PersonalizationComponent(props) {
  let public_dashboard = props.public || false;
  var personalizationHelper = new PersonalizationHelper();
  personalizationHelper.retrieve_indicators(public_dashboard);
  let rawIndicators = personalizationHelper.indicator_order;
  const userContext = useContext(UserContext);
  const [indicators, setIndicators] = useState(rawIndicators);
  const [bodyObj, setBodyObj] = useState({});
  const [shouldSave, setShouldSave] = useState(false);
  const [saveDb, setSaveDb] = useState(undefined);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const history = useHistory();
  let userDb = public_dashboard ? user["dashboards"][0] : user["dashboards"][1];
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    userContext.getUser(user.id);
    // eslint-disable-next-line
  }, [localStorage.getItem("user")]);

  function getUserIndicators() {
    let groupIndicators = [];
    for (let i = 0; i < user.indicators_groups.length; i++) {
      const group = user.indicators_groups[i];

      if (group.id !== 2 && public_dashboard) {
        continue;
      }

      if (group.indicators) {
        for (let j = 0; j < group.indicators.length; j++) {
          const indicator = group.indicators[j];
          groupIndicators.push(indicator);
        }
      }
    }
    let unique = [...new Set(groupIndicators)];
    return unique;
  }

  useEffect(() => {
    /* if the user has just one dashboard or hasn't the property items_id, then create a new one */
    if (user["dashboards"].length === 1 || !userDb.hasOwnProperty("items_id")) {
      setIndicators([]);
    }
    getUserIndicators();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (saveDb === true) {
      setSaveDb(false);
      const body = bodyObj;
      const id = userDb["id"];
      if (!userDb["items_id"] || userDb["items_id"].length === 0) {
        apiFunctions.Post(
          api_configuration.api_route.dashboard_items_api,
          body,
          () => userContext.getUser(user.id)
        );
      } else {
        apiFunctions.Post(
          api_configuration.api_route.dashboard_items_multiple_api(id),
          body,
          () => userContext.getUser(user.id)
        );
      }
      userContext.getUser(user.id);
    }
    // eslint-disable-next-line
  }, [saveDb]);

  function createItems(dashboard_string, dashboard_id) {
    // Function to parse Dashboard Template to Items
    const position_code = { left: 1, right: 2, complete: 3 };
    let dashb = JSON.parse(dashboard_string);
    const dashb_keys = Object.keys(dashb);
    let final_object = [];
    let half_keys = undefined;
    dashb_keys.forEach((key, index) => {
      let line_name = key;
      let line_id = index + 1;
      let dashb_id = dashboard_id;
      let line_position = undefined;
      let content_keys = Object.keys(dashb[key]);
      let obj = {};
      if (content_keys[0] === "complete") {
        line_position = 3;
        if (dashb[key]["complete"]["indicator_id"] != null) {
          obj = {
            dashboard_id: dashb_id,
            line_id: line_id,
            line_name: line_name,
            line_position: line_position,
            position_content: dashb[key]["complete"]["indicator_id"],
          };
        } else {
          obj = {
            dashboard_id: dashb_id,
            line_id: line_id,
            line_name: line_name,
            line_position: line_position,
          };
        }
        final_object.push(obj);
      } else {
        half_keys = Object.keys(dashb[key]["half"]);
        half_keys.forEach((content, idx) => {
          line_position = position_code[content];
          if (dashb[key]["half"][content]["indicator_id"] != null) {
            obj = {
              dashboard_id: dashb_id,
              line_id: line_id,
              line_name: line_name,
              line_position: line_position,
              position_content: dashb[key]["half"][content]["indicator_id"],
            };
          } else {
            obj = {
              dashboard_id: dashb_id,
              line_id: line_id,
              line_name: line_name,
              line_position: line_position,
            };
          }
          final_object.push(obj);
        });
      }
    });
    let response = { items_id: final_object };
    return JSON.stringify(response);
  }

  function newCard() {
    let newIndicators = [...indicators];
    newIndicators.push([0]);
    setIndicators(newIndicators);
  }

  /* generate json */
  function saveIndicators() {
    let jsonObj;
    let obj = {};
    obj = indicators.reduce((acc, cur, i) => {
      if (cur.length > 1) {
        acc[i + 1] = {
          half: {
            left: {
              indicator_id: cur[0],
            },
            right: {
              indicator_id: cur[1],
            },
          },
        };
      } else {
        acc[i + 1] = {
          complete: {
            indicator_id: cur[0],
          },
        };
      }

      return acc;
    }, {});
    jsonObj = JSON.stringify(obj); // json salvo no formato correto
    jsonObj = createItems(jsonObj, userDb["id"]);
    setBodyObj(JSON.parse(jsonObj)["items_id"]);
    setShouldSave(false);
    setSaveDb(true);
  }

  function redirectTo(path) {
    history.push({
      pathname: path,
    });
  }

  const [saveFlag, setSaveFlag] = useState(false);

  useEffect(() => {
    if (saveFlag) {
      redirectTo("/personalized_dashboard");
      setSaveFlag(false);
    }
    // eslint-disable-next-line
  }, [saveFlag]);

  function returnIndicator() {
    saveIndicators();
    setSaveFlag(true);
  }

  return (
    <div
      className={`${public_dashboard ? "public" : ""}main___page ${
        userContext.contrast
      } `}
    >
      <div className="row personalization_header_background">
        <Card
          cardType="complete"
          content={
            <div className="personalization-save-content">
              <p
                style={{ fontSize: "20px", fontWeight: 500, color: "#1b1b1b" }}
              >
                {" "}
                Personalização de Dashboard
              </p>
              <div>
                {!public_dashboard && (
                  <Button
                    bground="secondary"
                    label="Salvar e voltar"
                    pstate={
                      indicators.length > 0 && shouldSave ? "" : "disabled"
                    }
                    psize="small"
                    onclick={() => returnIndicator()}
                    inverted={userContext.contrast === "contrast" && "inverted"}
                  />
                )}

                <Button
                  bground="secondary"
                  label="Salvar"
                  pstate={indicators.length > 0 && shouldSave ? "" : "disabled"}
                  psize="small"
                  onclick={saveIndicators}
                  inverted={userContext.contrast === "contrast" && "inverted"}
                />
                {!public_dashboard && (
                  <Button
                    bground="secondary"
                    circle="small circle m-2"
                    icon="fas fa-arrow-left"
                    psize="small"
                    onclick={() => history.push("/personalized_dashboard")}
                    inverted={userContext.contrast === "contrast" && "inverted"}
                  />
                )}
              </div>
            </div>
          }
        />
      </div>

      <div className={`personalization_background${userContext.contrast}`}>
        {indicators.flatMap((elem, key) => (
          <div key={new Date() + elem + key} className="row">
            {elem.flatMap((elems, index) =>
              elems !== null ? (
                <div key={"persInd" + elems + index} className="row__inner">
                  <PersonalizationCard
                    availableIndicators={getUserIndicators()}
                    setShouldSave={setShouldSave}
                    id={elems}
                    keyValue={key}
                    index={index}
                    len={elem.length}
                    setIndicators={setIndicators}
                    indicators={indicators}
                  />
                </div>
              ) : (
                <div key={"persInd" + elems + index} className="row__inner">
                  <PersonalizationCard
                    availableIndicators={getUserIndicators()}
                    setShouldSave={setShouldSave}
                    id={-1}
                    keyValue={key}
                    index={index}
                    len={elem.length}
                    setIndicators={setIndicators}
                    indicators={indicators}
                  />
                </div>
              )
            )}
          </div>
        ))}
      </div>
      <div className={`personalization-add-content${userContext.contrast}`}>
        <Button
          icon="fas fa-plus"
          bground="secondary"
          circle="circle"
          psize="small"
          onclick={newCard}
          inverted={userContext.contrast === "contrast" && "inverted"}
        />
      </div>
    </div>
  );
}

export default PersonalizationComponent;
