/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useState, useEffect } from "react";
import "./Selection.css";
import Card from "../../ds-gov/Card";
import UserContext from "../../../Store/user-context";
import { useHistory } from "react-router-dom";
import DescriptionCardModal from "../modals/description-modal";
import * as apiFunctions from "../../../Store/apiFunctions"
import api_obj from "../../../api_configuration";

function DashboardSelectionComponent() {

  useEffect(() => {
    let tam = localStorage.getItem('countFont');
    let classElements = document.getElementsByTagName("*");
    apiFunctions.GetRegions(api_obj.api_route.region_api)
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize = (parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) * 1.05 + 'px');
      }
      j += 1;
    }
    userContext.setBreadLocation([{ name: "Dashboard", href: "/dashboard_selection", clickable: false }])
    // eslint-disable-next-line
  }, [])

  const userContext = useContext(UserContext);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  function redirectTo(id) {
    if (id === 0) {
      history.push(`/dashboard`);
    } else if (id === 1) {
      localStorage.getItem("user") === null
        ? setOpenModal(true)
        : history.push(`/personalized_dashboard`);
    }
  }

  let publicDashboardBody = (
    <div className={`dashboard${userContext.contrast}`} onClick={() => redirectTo(0)}>
      <p className="text">Dashboard</p>
      <p className="text">Público</p>
    </div>
  );

  let personalizationDashboardBody = (
    <div className={`dashboard${userContext.contrast}`} onClick={() => redirectTo(1)}>
      <p className="text">Dashboard</p>
      <p className="text">Personalizado</p>
    </div>
  );

  return (
    <div className={`background_${userContext.contrast}`}>
      <DescriptionCardModal
        open={openModal}
        onHandle={setOpenModal}
        cancelDescription="Fechar"
        description={"Você deve estar logado para acessar o dashboard personalizável."}
      />
      <div className="dashboard_selection_page">
          <Card
            cardType="complete" 
            cardContrast={userContext.contrast}
            content={publicDashboardBody}
          />
          {/*para aumentar o espaço entre cards*/}
        <div className="dashboard_box"></div>
        <Card
          cardType="complete"
          cardContrast={userContext.contrast}
          content={personalizationDashboardBody}
        />
      </div>
    </div>
  );
}

export default DashboardSelectionComponent;