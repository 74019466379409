/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import UserContext from "../../../Store/user-context";
import {
  retrieveStyle,
  retrieveIcons,
  Local,
  retrieveOpts,
  retrieveActionSearch,
} from "./configuration";
import FilterModal from "../modals/filter-modal";
import * as tableFunctions from "./tableManagementFunctions";
import * as apiFunctions from "../../../Store/apiFunctions";
import "./management.css";
import api_configuration from "../../../api_configuration";
import Input from "../../ds-gov/Input";
import CustomSelectFilters from "../../../helpers/CustomSelectFilters";
import Select from "../../ds-gov/Select";

function IndicatorsTable(props) {
  // eslint-disable-next-line
  let indicatorsData = useMemo(() => props.data || [], []);
  let shouldUpdate = props.shouldUpdateData || false;
  let setShouldUpdate = props.setShouldUpdateData || function () {};
  let filtersData = useMemo(() => props.filters || [], [props.filters]);
  let dims = props.dims || [];

  const userContext = useContext(UserContext);
  const filters = filtersData;
  const dimensions = dims;
  const [data, setData] = useState(indicatorsData);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [currentIndicator, setCurrentIndicator] = useState([]);
  useEffect(() => {
    setData(indicatorsData);
  }, [indicatorsData]);
  const [toggleSearch, setSearch] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState([]);
  const [removeFiltersOptions, setRemoveFiltersOptions] = useState([]);
  const [removeFiltersChecked, setRemoveFiltersChecked] = useState([]);
  const [filtersChecked, setFiltersChecked] = useState([]);
  const [dimensionsOptions, setDimensionsOptions] = useState(
    dims.map((dims) => {
      return {
        name: dims.dimension_name,
        id: dims.id,
        checked: false,
      };
    })
  );
  const [openRemoveIndicatorModal, setOpenRemoveIndicatorModal] =
    useState(false);
  const [removeDimensionsOptions, setRemoveDimensionsOptions] = useState([]);
  const [dimensionsChecked, setDimensionsChecked] = useState([]);
  const [removeDimensionsChecked, setRemoveDimensionsChecked] = useState([]);
  const [indicatorName, setIndicatorName] = useState("");
  const [indicatorCode, setIndicatorCode] = useState("");
  const [indicatorDescription, setIndicatorDescription] = useState("");
  const [indicatorRoute, setIndicatorRoute] = useState("");
  const [indicatorSource, setIndicatorSource] = useState("");
  const [indicatorPresentationShape, setIndicatorPresentationShape] =
    useState("");
  const [indicatorLabel, setIndicatorLabel] = useState("");
  const [loading, setLoading] = useState(undefined);
  const [collapseFields, setCollapseFields] = useState(true);
  const [collapseFilters, setCollapseFilters] = useState(true);
  const local = Local;
  let Tstyle = props.tstyle || retrieveStyle(userContext.contrast);
  let icons = retrieveIcons(userContext.contrast);
  const opts = retrieveOpts(Tstyle, toggleSearch);
  const actionSearch = props.iconSearch || retrieveActionSearch(userContext);

  const [options, setOptions] = useState([
    { name: "FNDE", checked: false },
    { name: "FNDE - SIMAD", checked: false },
    { name: "FNDE - SIMEC", checked: false },
  ]);

  useEffect(() => {
    setOptions([
      { name: "FNDE", checked: false },
      { name: "FNDE - SIMAD", checked: false },
      { name: "FNDE - SIMEC", checked: false },
    ]);
  }, []);

  /*set current indicator fields */
  useEffect(() => {
    setIndicatorName(currentIndicator.indicator_name);
    setIndicatorCode(currentIndicator.indicator_code);
    setIndicatorDescription(currentIndicator.indicator_description);
    setIndicatorRoute(currentIndicator.indicator_route);
    setIndicatorSource(currentIndicator.indicator_source);
    setIndicatorPresentationShape(
      currentIndicator.indicator_presentation_shape
    );
    setIndicatorLabel(currentIndicator.label);
    // eslint-disable-next-line
  }, [currentIndicator]);

  useEffect(() => {
    if (loading === false) {
      setShouldUpdate(!shouldUpdate);
    }
    // eslint-disable-next-line
  }, [loading]);

  function uniqueIndicatorFilters() {
    let indicatorFilters = [...currentIndicator.filters];
    let newFilters = [...filters];
    for (let i = 0; i < indicatorFilters.length; i++) {
      for (let j = 0; j < newFilters.length; j++) {
        if (newFilters[j].id === indicatorFilters[i].id) {
          newFilters.splice(j, 1);
        }
      }
    }
    let f = newFilters.map((filter) => {
      return {
        name: filter.filter_name,
        id: filter.id,
        checked: false,
      };
    });
    setFiltersOptions(f);
  }

  function uniqueIndicatorDims() {
    let indicatorDims = [...currentIndicator.dimensions];
    let newDims = [...dimensions];
    for (let i = 0; i < indicatorDims.length; i++) {
      for (let j = 0; j < newDims.length; j++) {
        if (newDims[j].id === indicatorDims[i].id) {
          newDims.splice(j, 1);
        }
      }
    }
    let f = newDims.map((dimsension) => {
      return {
        name: dimsension.dimension_name,
        id: dimsension.id,
        checked: false,
      };
    });
    setDimensionsOptions(f);
  }

  useEffect(() => {
    if (currentIndicator.filters && currentIndicator.dimensions) {
      uniqueIndicatorFilters();
      uniqueIndicatorDims();
      setRemoveFiltersOptions(
        currentIndicator.filters.map((filter) => {
          return {
            name: filter.filter_name,
            id: filter.id,
            checked: false,
          };
        })
      );

      setRemoveDimensionsOptions(
        currentIndicator.dimensions.map((dimension) => {
          return {
            name: dimension.dimension_name,
            id: dimension.id,
            checked: false,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [currentIndicator]);

  let acts;
  useEffect(() => {
    userContext.closeAccessibility();
    // eslint-disable-next-line
  }, []);

  window.onbeforeunload = function () {
    userContext.toggleAccessibility();
  };

  acts = [
    {
      icon: "add",
      tooltip: "Adicionar Indicador",
      onClick: (event) => tableFunctions.createNewIndicator(data, setData),
      isFreeAction: true,
    },
    {
      icon: "edit",
      tooltip: "Editar Indicador",
      onClick: (event, indicator) => {
        if (!indicator.filters) indicator.filters = [];
        if (!indicator.dimensions) indicator.dimensions = [];
        let opts = [...options];
        for (let i = 0; i < opts.length; i++) {
          const opt = opts[i];
          if (indicator.indicator_source === opt.name) {
            opt.checked = true;
          } else {
            opt.checked = false;
          }
        }
        setOptions(opts);
        setCurrentIndicator(indicator);
        tableFunctions.setOpenOptionsModal(
          openOptionsModal,
          setOpenOptionsModal
        );
      },
    },
    {
      icon: "delete",
      tooltip: "Remover Indicador",
      onClick: (event, indicator) => {
        setCurrentIndicator(indicator);
        setOpenRemoveIndicatorModal(!openRemoveIndicatorModal);
        //tableFunctions.deleteInicator(data, setData, indicator)
      },
    },
    {
      icon: actionSearch,
      tooltip: "busca",
      isFreeAction: true,
      onClick: (event) => setSearch(!toggleSearch),
    },
  ];

  let title = "Indicadores";
  let header_list = [
    {
      field: "indicator_name",
      title: "Nome",
      validate: (rowData) => rowData.indicator_name !== "",
    },
    {
      field: "indicator_code",
      title: "Código",
      validate: (rowData) => rowData.indicator_code !== "",
    },
    {
      field: "indicator_description",
      title: "Descrição",
      cellStyle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "5rem",
      },
      validate: (rowData) => rowData.indicator_description !== "",
    },
    {
      field: "indicator_route",
      title: "Rota do Indicador",
      editable: false,
    },
    {
      field: "indicator_source",
      title: "Fonte",
      validate: (rowData) => rowData.indicator_source !== "",
    },
    {
      field: "indicator_presentation_shape",
      title: "Formato",
    },
    {
      field: "last_update",
      title: "Ultima sincronização",
      editable: false,
    },
    {
      field: "label",
      title: "Label",
      validate: (rowData) => rowData.label !== "",
    },
  ];

  let removeIndicator = (
    <>
      <div style={{ fontSize: "15px", marginTop: "30px" }}>
        <p>
          O indicador será movido para o grupo de não publicado, isso fará com
          que ele não possa mais ser exibido, mas ele continuará no sistema.
        </p>
        <p style={{ marginTop: "10px" }}>Deseja continuar?</p>
      </div>
    </>
  );

  function handleChangeOptions(index) {
    let newOptions = [...options];
    for (let i = 0; i < newOptions.length; i++) {
      if (i !== index) {
        newOptions[i].checked = false;
      }
    }
    newOptions[index].checked = !newOptions[index].checked;
    setOptions(newOptions);
  }

  let modalContent = (
    <>
      <p style={{ fontSize: "1.25rem", fontWeight: "600" }}>
        {indicatorDescription || ""}
      </p>
      <div style={{ height: "1rem" }} />
      {/* collapse div to show fields */}
      <button
        type="button"
        onClick={() => setCollapseFields(!collapseFields)}
        className={`${userContext.contrast}indicator_collapsible_button_field`}
      >
        Editar Campos do Indicador{" "}
        <i
          className={collapseFields ? "fas fa-caret-down" : "fas fa-caret-up"}
        ></i>
      </button>
      <div className={collapseFields ? "collapse" : "show"}>
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Nome"}
          labelText={"Nome do Indicador"}
          labelFor={"indicator_name"}
          inputId={"indicator_name"}
          inputValue={indicatorName}
          handleChange={(e) => setIndicatorName(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Código"}
          labelText={"Código do Indicador"}
          labelFor={"indicator_code"}
          inputId={"indicator_code"}
          inputValue={indicatorCode}
          handleChange={(e) => setIndicatorCode(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Descrição"}
          labelText={"Descrição do Indicador"}
          labelFor={"indicator_description"}
          inputId={"indicator_description"}
          inputValue={indicatorDescription}
          handleChange={(e) => setIndicatorDescription(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Rota"}
          labelText={"Rota do Indicador"}
          labelFor={"indicator_route"}
          inputId={"indicator_route"}
          inputValue={indicatorRoute}
          handleChange={(e) => setIndicatorRoute(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Select
          constrast={userContext.contrast}
          options={options}
          handleChange={handleChangeOptions}
          placeholder={currentIndicator.indicator_source}
          label="Fonte do Indicador"
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Formato"}
          labelText={"Formato do Indicador"}
          labelFor={"indicator_presentation_shape"}
          inputId={"indicator_presentation_shape"}
          inputValue={indicatorPresentationShape}
          handleChange={(e) => setIndicatorPresentationShape(e.target.value)}
          onBlur={() => {}}
        />
        <div style={{ height: "1rem" }} />
        <Input
          inputPlaceholder={"Legenda"}
          labelText={"Legenda do Indicador"}
          labelFor={"label"}
          inputId={"label"}
          inputValue={indicatorLabel}
          handleChange={(e) => setIndicatorLabel(e.target.value)}
          onBlur={() => {}}
        />
      </div>

      {/* collapse div to show filters and dimensions */}
      <div style={{ height: "2rem" }} />
      <button
        type="button"
        onClick={() => setCollapseFilters(!collapseFilters)}
        className={`${userContext.contrast}indicator_collapsible_button_field`}
      >
        Editar Filtros e Dimensões do Indicador{" "}
        <i
          className={collapseFilters ? "fas fa-caret-down" : "fas fa-caret-up"}
        ></i>
      </button>
      <div className={collapseFilters ? "collapse" : "show"}>
        <div style={{ height: "1rem" }} />
        <div>
          {filtersOptions.length > 0 ? (
            <div>
              <CustomSelectFilters
                options={filtersOptions}
                label={"Adicionar Filtros"}
                placeholder="Selecione os filtros a serem adicionados ao indicador"
                handleChange={setFiltersChecked}
              />
            </div>
          ) : (
            <p>Todos os Filtros já estão no indicador</p>
          )}
          <div style={{ height: "1rem" }} />
          {removeFiltersOptions.length > 0 ? (
            <CustomSelectFilters
              options={removeFiltersOptions}
              label={"Remover Filtros"}
              placeholder={
                "Selecione os filtros a serem removidos do indicador"
              }
              handleChange={setRemoveFiltersChecked}
            />
          ) : (
            <p>Não há nenhum filtro no indicador</p>
          )}
        </div>
        <div style={{ height: "1rem" }} />
        <div>
          {dimensionsOptions.length >= 0 ? (
            <CustomSelectFilters
              options={dimensionsOptions}
              label={"Adicionar Dimensões"}
              placeholder={
                "Selecione as dimensões a serem adicionados ao indicador"
              }
              handleChange={setDimensionsChecked}
            />
          ) : (
            <p>Todas as Dimensões já estão no indicator</p>
          )}
          <div style={{ height: "1rem" }} />
          {removeDimensionsOptions.length > 0 ? (
            <CustomSelectFilters
              label={"Remover Dimensões"}
              placeholder={
                "Selecione as dimensões a serem removidas ao indicador"
              }
              options={removeDimensionsOptions}
              handleChange={setRemoveDimensionsChecked}
            />
          ) : (
            <p>Não há nenhuma dimensão no indicador</p>
          )}
        </div>
      </div>
    </>
  );

  function onSave() {
    if (
      indicatorName !== currentIndicator.indicator_name ||
      indicatorCode !== currentIndicator.indicator_code ||
      indicatorDescription !== currentIndicator.indicator_description ||
      indicatorRoute !== currentIndicator.indicator_route ||
      indicatorSource !== currentIndicator.indicator_source ||
      indicatorPresentationShape !==
        currentIndicator.indicator_presentation_shape ||
      indicatorLabel !== currentIndicator.label
    ) {
      let body = {
        indicator_name: indicatorName,
        indicator_code: indicatorCode,
        indicator_description: indicatorDescription,
        indicator_route: indicatorRoute,
        indicator_source: indicatorSource,
        indicator_presentation_shape: indicatorPresentationShape,
        label: indicatorLabel,
      };
      apiFunctions.Patch(
        api_configuration.api_route.single_indicator_api(currentIndicator.id),
        body,
        undefined,
        setLoading
      );
    }
    //add filters route
    if (filtersChecked.length > 0) {
      let finalfiltersOptions = filtersChecked.map((filterchecked) => {
        return {
          id_filter: filterchecked,
          id_indicator: Number(currentIndicator.id),
        };
      });
      apiFunctions.Post(
        api_configuration.api_route.indicator_filters_multiple_api,
        finalfiltersOptions,
        undefined,
        setLoading
      );
    }

    //remove filters route
    if (removeFiltersChecked.length > 0) {
      let finalRemove = removeFiltersChecked.map((filterchecked) => {
        return {
          id_filter: filterchecked,
          id_indicator: Number(currentIndicator.id),
        };
      });
      apiFunctions.Delete(
        api_configuration.api_route.indicator_filters_multiple_api,
        finalRemove,
        undefined,
        setLoading
      );
    }

    //add dimensions route
    if (dimensionsChecked.length > 0) {
      let finalCheckedDims = dimensionsChecked.map((dimensionchecked) => {
        return {
          id_dimension: dimensionchecked,
          id_indicator: Number(currentIndicator.id),
        };
      });
      apiFunctions.Post(
        api_configuration.api_route.indicator_dims_multiple_api,
        finalCheckedDims,
        undefined,
        setLoading
      );
    }

    //remove dimensions route
    if (removeDimensionsChecked.length > 0) {
      let finalCheckedRemoveDims = removeDimensionsChecked.map(
        (dimensionchecked) => {
          return {
            id_dimension: dimensionchecked,
            id_indicator: Number(currentIndicator.id),
          };
        }
      );
      apiFunctions.Delete(
        api_configuration.api_route.indicator_dims_multiple_api,
        finalCheckedRemoveDims,
        undefined,
        setLoading
      );
    }
  }

  return (
    <div className={`user-container ${userContext.contrast}`}>
      <MaterialTable
        title={title}
        columns={header_list}
        data={data}
        actions={acts}
        options={opts}
        localization={local}
        editable={false}
        style={Tstyle}
        icons={icons}
      />
      <FilterModal
        open={openRemoveIndicatorModal}
        onSave={() => {
          tableFunctions.deleteInicator(data, setData, currentIndicator);
        }}
        onHandle={() => {
          setOpenRemoveIndicatorModal(!openRemoveIndicatorModal);
        }}
        label={"Continuar"}
        icon={"none"}
        description={removeIndicator}
      />
      <FilterModal
        open={openOptionsModal}
        onSave={() => {
          onSave();
        }}
        onHandle={() => {
          setOptions([
            { name: "FNDE", checked: false },
            { name: "FNDE - SIMAD", checked: false },
            { name: "FNDE - SIMEC", checked: false },
          ]);
          setOpenOptionsModal(!openOptionsModal);
        }}
        label={"Salvar"}
        icon={"none"}
        description={modalContent}
      />
    </div>
  );
}

export default IndicatorsTable;
