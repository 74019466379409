// import LineChartComponent from "../Components/app/charts/line-chart";
import BarChartComponent from "../Components/app/charts/bar-chart";
import LineChartComponent from "../Components/app/charts/line-chart";
import BasicTable from "../Components/app/charts/table";
import List from "../Components/ds-gov/List";

function treatString(string) {
  let newString = "";
  let count = 0;
  for (let i = string.length - 1; i >= 0; i--) {
    const char = string[i];
    newString = char + newString;
    if (i > 0 && count > 0 && count % 3 === 0) {
      newString = "." + newString;
    }
    if (i <= string.length - 3) {
      count++;
    }
  }
  return newString;
}

function treatListDataIndicator(data) {
  const dataKeyArray = [];
  let dataKeyArrayUnique = [];
  let finalArray = [];
  data.forEach((element) => {
    dataKeyArray.push(element["year"].toString());
  });
  dataKeyArrayUnique = [...new Set(dataKeyArray)];
  dataKeyArrayUnique.forEach((uniqueYear) => {
    finalArray.push({
      label: uniqueYear,
      total: 0,
      list: [],
    });
  });

  finalArray.forEach((finalElement) => {
    data.forEach((dataElement, i) => {
      if (finalElement["label"] === dataElement["year"].toString()) {
        finalElement.total = finalElement.total + 1;
        finalElement.list.push({
          primaryText:
            dataElement["total"] !== undefined
              ? `${dataElement["name"]} - R$ ${treatString(
                Number(dataElement["total"]).toFixed(2).replace(".", ",")
              )}`
              : dataElement["name"],
          secondaryText: "",
          icon: "fas fa-building",
        });
      }
    });
  });
  /*return sorted array by period */
  return finalArray.sort((a, b) => {
    return a.label - b.label;
  });
}


function treatData(data, keys, mesoregionsId, renamedDefaultKey) {
  const dataKeyArray = [];
  let dataKeyArrayUnique = [];
  let finalArray = [];
  data.forEach((element) => {
    dataKeyArray.push(element["year"].toString());
  });
  dataKeyArrayUnique = [...new Set(dataKeyArray)];
  dataKeyArrayUnique.forEach((uniqueYear) => {
    let finalArrayObject = {
      period: uniqueYear,
    };
    data.forEach((element) => {
      if (Number(element["year"]) === Number(uniqueYear)) {
        if (element.hasOwnProperty("city")) {
          finalArrayObject[`${element["city"]}`] = Number(element["total"]);
        } else if (element.hasOwnProperty("mesoregion")) {
          for (let i = 0; i < mesoregionsId.length; i++) {
            keys[1].forEach((key) => {
              if (
                Number(mesoregionsId[i]["id"]) === Number(key["id"]) &&
                mesoregionsId[i]["uf"] === element["uf"] &&
                Number(mesoregionsId[i]["id"].toString().substring(2, 4)) ===
                Number(element["mesoregion"])
              ) {
                finalArrayObject[`${key["name"]}`] = Number(element["total"]);
              }
            });
          }
        } else if (element.hasOwnProperty("uf")) {
          finalArrayObject[`${element["uf"]}`] = Number(element["total"]);
        } else if (element.hasOwnProperty("region")) {
          keys[0].forEach((key) => {
            if (Number(element["region"]) === key["id"]) {
              finalArrayObject[`${key["name"]}`] = Number(element["total"]);
            }
          });
        } else if(element.hasOwnProperty("stage")) {
          if(typeof element["grade_code"] === "number") finalArrayObject[`${element["stage"]}`] = Number(element["total"]);
        }
        else {
          finalArrayObject[renamedDefaultKey] = Number(element["total"]);
        }
      }
    });
    finalArray.push(finalArrayObject);
  });
  /*return sorted array by period */
  if (finalArray[0] !== undefined && Object.keys(finalArray[0]).length > 1) {
    return finalArray.sort((a, b) => {
      return a.period - b.period;
    });
  }
  return [];
}

export default function PlotGraph(params) {
  const {
    chartType,
    isStackedBar,
    data,
    keys,
    mesoregionsId,
    contrast,
    fullPage,
    label,
    presentationShape
  } = params;

  let transformed;
  if (presentationShape === "list") transformed = treatListDataIndicator(data)
  else transformed = treatData(
    data,
    keys,
    mesoregionsId,
    label
  );
  if (presentationShape === "list") {
    return (
      <List
        title={label}
        expandable={true}
        contrast={contrast}
        content={treatListDataIndicator(data)}
        fullPage={fullPage}
        divide={true}
      />
    );
  }
  if (Number(chartType) === 1) {
    return (
      <LineChartComponent
        contrast={contrast}
        data={transformed}
        keys={data}
        fullPage={fullPage}
      />
    );
  } else if (Number(chartType) === 2) {
    return (
      <BarChartComponent
        contrast={contrast}
        data={transformed}
        isStackedBar={false}
        fullPage={fullPage}
        keys={data}
        label={label}
      />
    );
  } else if (Number(chartType) === 4) {
    return (
      <BarChartComponent
        contrast={contrast}
        data={transformed}
        isStackedBar={isStackedBar}
        fullPage={fullPage}
        keys={data}
        label={label}
      />
    );
  } else if (Number(chartType) === 3) {
    return (
      <BasicTable data={transformed} />
    );
  }

}
