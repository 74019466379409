/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from "react";
import "./recover.css";
import Input from "../../ds-gov/Input";
import Button from "../../ds-gov/Button";
import Card from "../../ds-gov/Card";
import {
  handleShowPassword,
  handleFieldChange,
  isFieldValid,
  isFieldInvalid,
  validatePassword,
  validateConfirmPassword,
  handleEmailValidation,
  handleValidateBirthDateField,
} from "../modals/utils/validation";
import UserContext from "../../../Store/user-context";
import NotificationContext from "../../../Store/notification-store";
import * as apiFunctions from "../../../Store/apiFunctions"
import api_configuration from "../../../api_configuration";

function RecoverComponent() {
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [isEdittingPassword, setEdittingPassword] = useState(false);
  const [isEdittingConfirmPassword, setEdittingConfirmPassword] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(undefined);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    useState(undefined);
  const [birthDateField, setBirthDateField] = useState("");
  const [isBirthDateValid, setIsBirthDateValid] = useState(undefined);
  const [emailField, setEmailField] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [token, setToken] = useState("");

  useEffect(() => {
    userContext.setBreadLocation([{ name: "Recuperação de Senha", href: "/recupera", clickable: false }])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newPassword.length > 0) {
      validatePassword(setIsPasswordValid, newPassword);
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPassword,
        newPassword
      );
    } else setEdittingPassword(false);
    // eslint-disable-next-line
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword.length > 0) {
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPassword,
        newPassword
      );
    } else setEdittingConfirmPassword(false);
    // eslint-disable-next-line
  }, [confirmPassword]);

  useEffect(() => {
    if (birthDateField.length > 0) {
      handleValidateBirthDateField(setIsBirthDateValid, birthDateField);
    } else setIsBirthDateValid(undefined);
  }, [birthDateField]);

  useEffect(() => {
    if (emailField.length > 0)
      handleEmailValidation(setIsEmailValid, emailField);
    else setIsEmailValid(undefined);
  }, [emailField]);

  const handleShowNotification = (title, message, status) => {
    notificationContext.showNotification({
      title: title,
      message: message,
      status: status,
      position: "top-right",
    });
  };

  // atualiza o usuario
  const resetFields = () => {
    setNewPassword("");
    setConfirmPassword("");
    setToken("");
    setBirthDateField("");
    setEmailField("")
    setIsBirthDateValid(undefined);
    setIsEmailValid(undefined)
    setEdittingPassword(false);
    setEdittingConfirmPassword(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setIsConfirmPasswordValid(undefined);
    setIsPasswordValid(undefined);
  };

  //atualiza o usuário caso houver mudanca valida de email, senha, funcao ou local de trabalho
  const handleSaveUser = () => {
    resetFields();
    let body = {
      password: newPassword,
      birth_date: birthDateField,
      email: emailField,
      resetToken: token,
    }
    apiFunctions.Put(api_configuration.api_route.reset_password_api, body, () => handleShowNotification("Sucesso!", "Sua senha foi atualizada", "success"))
  };

  return (
    <div className="div-container-recover">
      <div className={`${userContext.contrast}outer-container-recover`}>
        <div className="perfil-container">
          <h2 className={`${userContext.contrast}perfil-tile`} style={{ marginTop: "60px" }}>
            Recuperação de Senha
          </h2>
        </div>
        <div className="sized-box" />
        <div>
          <div className="card-content-recover">
            <Card
              cardContrast={userContext.contrast}
              cardType="complete"
              content={
                <div className="perfil-card" style={userContext.contrast === "contrast" ? { backgroundColor: "#1f3860" } : { backgroundColor: "white" }}>
                  <div>
                    <Input
                      brInputClassName={
                        isFieldValid(isBirthDateValid)
                          ? `br-input success input-${userContext.contrast}`
                          : isFieldInvalid(isBirthDateValid)
                            ? `br-input danger input-${userContext.contrast}`
                            : `br-input input-${userContext.contrast}`
                      }
                      showMessage={
                        isFieldValid(isBirthDateValid) ||
                        isFieldInvalid(isBirthDateValid)
                      }
                      messageText={
                        isFieldValid(isBirthDateValid)
                          ? "A data é válida"
                          : "A data é inválida, verifique se a data inserida é maior do que hoje ou se está fora do padrão exigido"
                      }
                      messageIcon={
                        isFieldValid(isBirthDateValid)
                          ? "fas fa-check-circle"
                          : "fas fa-times-circle"
                      }
                      feedBackType={
                        isFieldValid(isBirthDateValid)
                          ? "success"
                          : isFieldInvalid(isBirthDateValid)
                            ? "danger"
                            : ""
                      }
                      labelText="Data de nascimento"
                      inputType="date"
                      inputValue={birthDateField}
                      inputPlaceholder="mm/dd/yyyy"
                      handleChange={(e) =>
                        handleFieldChange(e, setBirthDateField)
                      }
                      onBlur={() => { }}
                      labelFor="birth-date"
                      inputId="birth-date"
                      hasToolTip={true}
                      toolTipText="O padrão exigido é mês/dia/ano"
                    />
                    <div className="sized-box" />
                    <Input
                      brInputClassName={
                        isFieldValid(isEmailValid)
                          ? `br-input success input-${userContext.contrast}`
                          : isFieldInvalid(isEmailValid)
                            ? `br-input danger input-${userContext.contrast}`
                            : `br-input input-${userContext.contrast}`
                      }
                      showMessage={
                        isFieldValid(isEmailValid) || isFieldInvalid(isEmailValid)
                      }
                      messageText={
                        isFieldValid(isEmailValid)
                          ? "O email é válido"
                          : "O email é inválido"
                      }
                      messageIcon={
                        isFieldValid(isEmailValid)
                          ? "fas fa-check-circle"
                          : "fas fa-times-circle"
                      }
                      feedBackType={
                        isFieldValid(isEmailValid)
                          ? "success"
                          : isFieldInvalid(isEmailValid)
                            ? "danger"
                            : ""
                      }
                      labelText="Email"
                      inputType="email"
                      inputValue={emailField}
                      inputPlaceholder="Digite seu email"
                      handleChange={(e) => handleFieldChange(e, setEmailField)}
                      onBlur={() => { }}
                      labelFor="email"
                      inputId="email"
                    />
                    <div className="sized-box" />
                    <Input
                      brInputClassName={`br-input input-${userContext.contrast}`}
                      labelText="Token"
                      inputType={"text"}
                      inputValue={token}
                      inputPlaceholder="Token"
                      handleChange={(e) => {
                        handleFieldChange(e, setToken);
                      }}
                      onBlur={() => { }}
                      labelFor="input-token"
                      inputId="input-token"
                    />
                    <div className="sized-box" />
                  </div>
                  <div className="password-half">
                    <div
                      className="right-input"
                      onClick={() => userContext.closeAccessibility()}
                      onFocus={() => userContext.closeAccessibility()}
                      onBlur={() => userContext.toggleAccessibility()}
                    >
                      <Input
                        brInputClassName={
                          isEdittingPassword
                            ? isFieldValid(isPasswordValid)
                              ? `br-input success input-${userContext.contrast} password-input`
                              : ` br-input danger input-${userContext.contrast} password-input `
                            : `br-input input-${userContext.contrast} password-input`
                        }
                        showMessage={
                          isEdittingPassword
                            ? isFieldValid(isPasswordValid) ||
                            isFieldInvalid(isPasswordValid)
                            : false
                        }
                        messageText={
                          isFieldValid(isPasswordValid)
                            ? "A senha é válida"
                            : "A senha é inválida, precisa ter 8 caracteres ou mais"
                        }
                        messageIcon={
                          isFieldValid(isPasswordValid)
                            ? "fas fa-check-circle"
                            : "fas fa-times-circle"
                        }
                        feedBackType={
                          isFieldValid(isPasswordValid)
                            ? "success"
                            : isFieldInvalid(isPasswordValid)
                              ? "danger"
                              : ""
                        }
                        labelText="Nova Senha"
                        inputType={showPassword ? "text" : "password"}
                        inputValue={newPassword}
                        inputPlaceholder="nova senha"
                        handleChange={(e) => {
                          handleFieldChange(e, setNewPassword);
                          setEdittingPassword(true);
                        }}
                        onBlur={() => { }}
                        labelFor="input-new-password"
                        inputId="input-new-password"
                        hasButton={true}
                        buttonClassName="br-button circle small"
                        buttonType="button"
                        buttonIcon={
                          showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        handleClick={() => handleShowPassword(setShowPassword)}
                      />
                    </div>
                    <div className="sized-box" />
                    <div
                      className="left-input"
                      onClick={() => userContext.closeAccessibility()}
                      onFocus={() => userContext.closeAccessibility()}
                      onBlur={() => userContext.toggleAccessibility()}
                    >
                      <Input
                        brInputClassName={
                          isEdittingConfirmPassword
                            ? isFieldValid(isPasswordValid)
                              ? isFieldValid(isConfirmPasswordValid)
                                ? `br-input success input-${userContext.contrast} password-input`
                                : ` br-input danger input-${userContext.contrast} password-input`
                              : ` br-input danger input-${userContext.contrast} password-input`
                            : `br-input input-${userContext.contrast} password-input`
                        }
                        showMessage={
                          isEdittingConfirmPassword
                            ? isFieldValid(isConfirmPasswordValid) ||
                            isFieldInvalid(isConfirmPasswordValid)
                            : false
                        }
                        messageText={
                          isFieldValid(isPasswordValid)
                            ? isFieldValid(isConfirmPasswordValid)
                              ? "As senhas são iguais"
                              : "As senhas não são iguais"
                            : "A senha é inválida, precisa ter 8 caracteres ou mais"
                        }
                        messageIcon={
                          isFieldValid(isPasswordValid)
                            ? isFieldValid(isConfirmPasswordValid)
                              ? "fas fa-check-circle"
                              : "fas fa-times-circle"
                            : "fas fa-times-circle"
                        }
                        feedBackType={
                          isFieldValid(isPasswordValid)
                            ? isFieldValid(isConfirmPasswordValid)
                              ? "success"
                              : "danger"
                            : "danger"
                        }
                        labelText="Confirme sua senha"
                        inputType={showConfirmPassword ? "text" : "password"}
                        inputValue={confirmPassword}
                        inputPlaceholder="confirme sua senha"
                        handleChange={(e) => {
                          handleFieldChange(e, setConfirmPassword);
                          setEdittingConfirmPassword(true);
                        }}
                        onBlur={() => { }}
                        labelFor="input-confirm-new-password"
                        inputId="input-confirm-new-password"
                        hasButton={true}
                        buttonClassName="br-button circle small"
                        buttonType="button"
                        buttonIcon={
                          showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        handleClick={() =>
                          handleShowPassword(setShowConfirmPassword)
                        }
                      />
                    </div>
                  </div>
                  <div className="sized-box" />
                  <div className="save-btn-container" style={{ marginLeft: "-12px" }}>
                    <Button
                      bground={
                        userContext.contrast === "contrast"
                          ? "secondary"
                          : "primary"
                      }
                      psize="large"
                      pstate={
                        isPasswordValid && isConfirmPasswordValid && token !== ""
                          ? ""
                          : "disabled"
                      }
                      label="Recuperar Senha"
                      onclick={handleSaveUser} //recover password
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="invisible-div" />
        </div>
      </div>
    </div>
  );
}

export default RecoverComponent;
